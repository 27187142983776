import * as React from "react";
import styled from "@emotion/styled";
import { Collapse } from "antd";

import mapImg from "../images/ultra-otef-2022-map.jpg";
import heroBg from "../images/ultra-otef-100-desktop.jpg";
import ultraImg from "../images/ultra-banner-opt.jpg";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

const Hero = styled.div`
  width: 100%;
  padding: 8rem 4rem;
  background-repeat: no-repeat;
  background-size: cover;
 background-position: 22% 56%;

  background-image: url(${heroBg});
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h2 {
    background-color: rgb(255 255 255 / 74%);
    padding: 1rem 2rem;
    text-align: center;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.2em;
  }

  @media screen and (max-width: 768px) {
    background-position: background-position: 22% 50%;

    h1 {
      font-size: 1.3em;
    }

    h2 {
      font-size: 1.1em;
    }
  }
`;

// const SignupButton = styled.a`
//   font-size: 1em;
// `;

const Updates = styled.div`
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  padding: 1rem;

  h2 {
    margin-top: 0;
    padding-top: 0;
  }
`;

// markup
const IndexPage = ({ location }) => {
  return (
    <Layout path={location.pathname} title="פרטי המירוץ">
      <Hero>
        <h1>אולטרה עוטף עזה 2022</h1>
        <h2>2.3.2022</h2>
        {/* <h2>
          <SignupButton
            title="הרשמה אולטרה עוטף עזה 2022"
            href="https://eventbuzz.co.il/lp/event/trmkb"
          >
            להרשמה למירוץ לחצו כאן
          </SignupButton>
        </h2> */}
      </Hero>
      <SectionContent title="פרטי המירוץ">
        <Updates>
          <h2>עדכונים</h2>
          <Collapse defaultActiveKey={["3"]}>
            <Collapse.Panel header="המרוץ לאזרחים בוטל" key="3">
              <p>
                <strong style={{color: 'red'}}>המרוץ לאזרחים 2022 מבוטל</strong>
              </p>
            </Collapse.Panel>
            <Collapse.Panel header="עדכון שעת זינוק" key="2">
              <p>
                <strong>שעת זינוק 6:00 אנדרטת הפלדה</strong>
              </p>
            </Collapse.Panel>
            <Collapse.Panel header="מקטע אישי" key="1">
              <p>
                <strong>המקטע האישי</strong>
              </p>
              <p>
                נפתחה האפשרות לרוץ מקטע לפי בחירה אישית , ללא צורך בקבוצת
                שליחים. אפשר לבחור מקטע כל שהוא לאורך המרוץ, ולרוץ אותו עצמאית.
                או אפילו מס חברים שמעדיפים לרוץ יחד.
              </p>
              <p>
                אפשר לבחור מס מקטעים שונים לאורך המסלול, כל עוד יוצאים מכל מקטע,
                במועד פתיחת התחנה כפי שיופיע בלוח זמנים של פתיחת תחנות החלפה.
              </p>
              <p>
                בהרשמה, לבחור סוג ריצה. אישי מס מקטע. התשלום הוא לפי כמות הקטעים
                שבוחרים.
              </p>
              <a
                title="הרשמה אולטרה עוטף עזה 2022"
                href="https://eventbuzz.co.il/lp/event/trmkb"
              >
                להרשמה לחצו כאן
              </a>
            </Collapse.Panel>
          </Collapse>
        </Updates>
        <h2>בואו לרוץ משחור לירוק...</h2>

        <h3>מרוץ אולטרה עוטף עזה יתקיים בשני למרץ 2022 (2.3.2022)</h3>
        <p>
          המרוץ מתקיים בשיתוף אוגדת עזה,{" "}
          <strong>הקבוצה האתגרית של עוטף עזה</strong> והמועצות האזורית.
        </p>

        <p>
          נקודת הזינוק למירוץ תמוקם באנדרטת עוצבת הפלדה ומסלולו נמשך לאורך 101
          ק&quot;מ בדרכי עפר, דרך יישובי פתחת שלום, חבל הבשור, שער הנגב, יישובי
          דרום חוף אשקלון עד לנקודת הסיום בחוף זיקים.
        </p>
        <p>
          המירוץ יערך לאורך כל היום, החל מזריחה ועד לשקיעה ביום רביעי בשבוע.
          המרוץ נחלק ל-12 מקטעים של עד 10 ק&quot;מ כל אחד. המשתתפים מתחלפים
          במקטעים השונים במתכונת של מרוץ שליחים. הקבוצות מתחלקות למקצים בני 4, 6
          או 8 משתתפים כל אחת, הזינוק למירוץ מדורג בהתאם ליכולת המשתתפים, וכושר
          ספיקת המסלול. האירוע כולו, נמשך מ-6:00 בבוקר, הזנקה ועד 18:00. טקס
          סיום בשעה 15:30 בחוף זיקים.
        </p>
        <div>
          <img
            src={ultraImg}
            style={{ height: "auto", width: "100%", margin: "1rem 0" }}
            width="1280"
            height="611"
            alt="אולטרה עוטף עזה"
          />
        </div>
        <h3>מטרת המירוץ</h3>
        <p>
          שילוב ייחודי של אהבת ספורט, טבע וחברותא כערכי יסוד של ההשתתפות במרוץ,
          ריצה קבוצתית שתכליתה הנאה, אתגר המשלב את הטבע ונופי הנגב המערבי,
          התושבים וצה&quot;ל כחלק בלתי נפרד מחווית הריצה.
        </p>
        <p>
          קבוצות הרצים אחראיות לניוד חברי הקבוצה בין התחנות ודאגה לצורכי המזון
          והשתיה שלהם.
        </p>
        <p>
          האירוע כולו מבוסס על רוח ההתנדבות וכולל את השתתפותם של מתנדבים תושבי
          האזור ואוגדת אזח&quot;ע.
        </p>
        <h3>מסלול המרוץ</h3>
        <p>
          יעבור דרך יישובי פתחת שלום, גבולות, צאלים, מחנה רעים, קיבוץ רעים,
          בארי, עלומים, נחל עוז, מפח&quot;ט נחל עוז, כפר עזה, אנדרטת 'חץ שחור'
          (מפלסים), ניר עם, ארז, יד מרדכי, כרמיה זיקים, חוף זיקים.
        </p>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <a href="https://www.strava.com/routes/2913050437659656586">
            <img
              style={{ height: "auto", width: "100%" }}
              src={mapImg}
              alt="מפה המירוץ - אולטרה עוטף עזה"
              width="403"
              height="414"
            />
            <br />
            לחצו לצפייה במפה וירטואלית
          </a>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default IndexPage;
